<template>
  <el-select
    v-model="address"
    class="select-address"
    filterable
    remote
    placeholder="Nhập tên địa chỉ để tìm kiếm"
    :remote-method="searchAddresss"
    :loading="loading"
    @change="handleChangeAddress"
  >
    <el-option
      v-for="item in addresses"
      :key="item.place_id"
      :label="item.address"
      :value="item.place_id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getAddresses, getAddress } from '@/services/goong.js'

export default {
  name: 'SelectAddress',
  props: {
    value: Object
  },
  data() {
    return {
      address: this.value ? this.value.place_id : '',
      loading: false,
      addresses: []
    }
  },
  beforeMount() {
    if (this.value) {
      this.addresses = [
        {
          place_id: this.value.place_id,
          address: this.value.address,
          latitude: this.value.latitude,
          longitude: this.value.longitude
        }
      ]
    }
  },
  methods: {
    async searchAddresss(searchText) {
      this.loading = true
      const addresses = await getAddresses(searchText).then((res) => {
        return res.predictions || []
      }).catch(() => {
        return []
      })
      const requests = addresses.map((a) => {
        return getAddress(a.place_id)
      })
      await Promise.all(requests).then((response) => {
        this.addresses = response.map((r) => ({
          place_id: r.result.place_id,
          address: r.result.formatted_address,
          latitude: r.result.geometry.location.lat,
          longitude: r.result.geometry.location.lng
        }))
        this.loading = false
      })
    },
    handleChangeAddress(placeId) {
      this.address = placeId
      const place = this.addresses.find((a) => a.place_id === placeId)
      if (place) {
        this.$emit('input', place)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-address {
  width: 100%;
}
</style>
