<template>
  <el-dialog
    top="80px"
    width="700px"
    class="food-form-popup"
    :title="`${!food ? 'Tạo mới ' : 'Cập nhật thông tin'} món ăn`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      ref="form"
      v-loading="loading"
      class="pt-2 pr-2"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Tên món ăn" prop="summary">
        <el-input
          v-model="form.summary"
          :disabled="callingAPI"
          placeholder="Nhập vào tên món ăn"
        ></el-input>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :lg="12">
          <el-form-item label="Loại món ăn" prop="dish_type">
            <el-select
              v-model="form.dish_type"
              placeholder="Chọn loại món ăn"
              disabled
            >
              <el-option
                v-for="t in dish_types"
                :key="t.value"
                :value="t.value"
                :label="t.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="STT hiển thị">
            <el-input
              v-model="form.order"
              :disabled="callingAPI"
              type="number"
              placeholder="Nhập stt hiển thị mong muốn"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Nhãn dán món ăn">
        <el-select
          v-model="form.labels"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="Chọn danh sách nhãn dán"
        >
          <el-option
            v-for="label in labels"
            :key="label.label"
            :label="label.label"
            :value="label.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          :rows="3"
          :disabled="callingAPI"
          type="textarea"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="foodInfo && visible" lang>
        <pictures :data.sync="foodInfo" type="dish" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        type="primary"
        :disabled="loading"
        :loading="callingAPI"
        @click="!foodInfo ? handleCreateFood() : handleUpdateFood()"
      >
        {{ !foodInfo ? "Tạo mới" : "Cập nhật" }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import Pictures from '../cards/Pictures.vue'
import CONSTANTS from '@/config/constants'
import { createFood, updateFood, getFoods } from '@/services/food'

export default {
  name: 'CreateFood',
  components: {
    Pictures
  },
  props: {
    visible: Boolean,
    food: Object,
    object: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false,
      loading: false,
      labels: CONSTANTS.DISH_LABELS,
      form: {
        summary: '',
        order: 0,
        description: '',
        labels: [],
        dish_type: this.type,
        price: 0,
        dish_size: 'M'
      },
      dish_types: CONSTANTS.DISH_TYPES,
      foodInfo: null,
      rules: {
        summary: [
          { required: true, message: 'Tên món ăn không được để trống', trigger: ['blur', 'change'] }
        ],
        dish_type: [
          { required: true, message: 'Loại món ăn không được để trống', trigger: ['blur', 'change'] }
        ]
      },
      foodSizes: []
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('food', ['updateReloadFoodGroup']),

    loadData() {
      if (this.visible && this.food) {
        this.getDishSizes()
        this.foodInfo = this.food
        this.form = {
          summary: this.food.summary,
          order: this.food.order,
          description: this.food.description,
          labels: this.food.labels ? this.food.labels.split(',') : [],
          dish_type: this.food.dish_type
        }
      } else {
        this.form = {
          summary: '',
          order: 0,
          description: '',
          labels: [],
          dish_type: this.type,
          price: 0,
          dish_size: 'M'
        }
        this.foodInfo = null
      }
    },

    getDishSizes() {
      this.loading = true
      getFoods({
        place_id: this.object.id,
        locale: this.language,
        page: 1,
        per_page: 5,
        dish_size_group_id: this.food.dish_size_group_id
      }).then((response) => {
        this.foodSizes = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleCreateFood() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createFood({
            ...this.form,
            locale: this.language,
            place_id: this.object.id,
            labels: this.form.labels.join(',')
          }).then(() => {
            this.callingAPI = false
            this.handleClose()
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới món ăn thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    handleUpdateFood() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          const requests = this.foodSizes.map((food) => this.requestUpdateFood(food))
          Promise.all(requests).then(() => {
            this.callingAPI = false
            this.$emit('reload')
            this.handleClose()
            this.updateReloadFoodGroup(dayjs().unix())
            this.$notify.success({
              title: 'Thông báo',
              message: 'Cập nhật món ăn thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },

    requestUpdateFood(food) {
      return updateFood({
        ...food,
        ...this.form,
        image_id: this.foodInfo.image_id,
        images: food.dish_size === 'M' ? this.foodInfo.images.map((i) => ({
          image_id: i.id,
          order: i.ordered
        })) : [],
        labels: this.form.labels.join(','),
        locale: this.language
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.food-form-popup {
  .el-form {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
