<template>
  <div class="parent one-component-detail-admin">
    <div class="title-component-admin">Danh thắng cha</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="Loại điểm đến">
        <el-select
          v-model="data.object.place_type"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option v-for="t in placeTypes" :key="t.value" :label="t.name" :value="t.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Điểm đến">
        <el-select
          v-model="data.object_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'Parent',
  props: {
    data: Object
  },
  data() {
    return {
      searchText: '',
      searching: false,
      places: [
        { id: this.data.object_id, name: this.data.object.name }
      ]
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    params() {
      return {
        locale: this.language,
        place_types: this.data.object.place_type,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  methods: {
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
        if (!this.data.object_id) {
          this.data.object_id = this.places[0].id
        }
      }).catch(() => {
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.data.object_id = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
