<template>
  <div class="campaign-type one-component-detail-admin">
    <div class="title-component-admin">Thời gian áp dụng</div>
    <el-form :model="data" class="p-3">
      <el-form-item>
        <el-date-picker
          :value="[data.start_time, data.end_time]"
          disabled
          type="daterange"
          range-separator="-"
          start-placeholder="Ngày bắt đầu"
          end-placeholder="Ngày kết thúc"
          format="dd-MM-yyyy"
          value-format="dd/MM/yyyy"
          class="w-100"
        ></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dates',
  props: {
    data: Object,
    type: String
  },
  computed: {
    ...mapGetters(['constant'])
  }
}
</script>

<style lang="scss" scoped>
</style>
