<template>
  <el-dialog
    top="80px"
    width="550px"
    class="add-place"
    title="Thêm mới điểm đến"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Thời gian bắt đầu" prop="startTime">
        <el-time-picker
          v-model="form.startTime"
          value-format="HH:mm"
          placeholder="Chọn thời gian bắt đầu"
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="Loại điểm đến" prop="placeType">
        <el-select
          v-model="form.placeType"
          :disabled="callingAPI"
          filterable
          @change="handleChangePlaceType"
        >
          <el-option v-for="p in placeTypes" :key="p.value" :label="p.name" :value="p.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Điểm đến" prop="placeId">
        <el-select
          v-model="form.placeId"
          :disabled="callingAPI"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input v-model="form.description" placeholder="Nhập mô tả" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleSaveData">Lưu thông tin</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createTourDes, updateTourDes } from '@/services/tour'
import { listPlaces } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'AddPlace',
  props: {
    visible: Boolean,
    tour: Object,
    schedule: Object,
    data: Object
  },
  data() {
    return {
      callingAPI: false,
      searching: false,
      places: [],
      searchText: '',
      form: {
        startTime: '',
        description: '',
        placeId: '',
        placeType: ''
      },
      rules: {
        startTime: [
          {
            required: true,
            message: 'Thời gian không được để trống.',
            trigger: ['blur, change']
          }
        ],
        placeType: [
          {
            required: true,
            message: 'Loại điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ],
        placeId: [
          {
            required: true,
            message: 'Điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    itemInput() {
      return {
        day_id: this.schedule.id,
        description: this.form.description,
        locale: this.language,
        place_id: this.form.placeId,
        place_type: this.form.placeType,
        start_time: this.form.startTime,
        tour_id: this.tour.id
      }
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.placeType,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.data) {
        this.places = [this.data.place]
        this.form.placeId = this.data.place.id
        this.form.startTime = this.data.start_time
        this.form.placeType = this.data.place.place_type
        this.form.description = this.data.description
      } else {
        this.form.placeType = 'attraction'
        this.getPlaces()
      }
    },

    getPlaces() {
      this.searching = true
      listPlaces(this.params).then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },

    handleChangePlaceType() {
      this.form.placeId = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },

    handleSaveData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.data) {
            this.updateTourDes()
          } else {
            this.createTourDes()
          }
        }
      })
    },

    createTourDes() {
      this.callingAPI = true
      createTourDes(this.itemInput).then(() => {
        this.callingAPI = false
        this.$emit('close')
        this.$emit('reload')
        this.$notify.success({
          title: 'Thông báo',
          message: 'Tạo mới thành công'
        })
      }).catch((error) => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    },

    updateTourDes() {
      this.callingAPI = true
      updateTourDes({ ...this.data, ...this.itemInput, locale: this.language }).then(() => {
        this.callingAPI = false
        this.$emit('close')
        this.$emit('reload')
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật thành công'
        })
      }).catch((error) => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-place {
  .el-select {
    width: 100%;
  }
}
</style>
