import { render, staticRenderFns } from "./AddPlace.vue?vue&type=template&id=4e82c59a&scoped=true&"
import script from "./AddPlace.vue?vue&type=script&lang=js&"
export * from "./AddPlace.vue?vue&type=script&lang=js&"
import style0 from "./AddPlace.vue?vue&type=style&index=0&id=4e82c59a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e82c59a",
  null
  
)

export default component.exports