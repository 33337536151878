<template>
  <el-row v-loading="loading || callingAPI" class="shared-show">
    <el-col v-if="!loading" :lg="15" class="shared-show__left">
      <div class="setting-price one-component-detail-admin">
        <div class="title-component-admin">
          <el-row type="flex" align="center" justify="space-between">
            <span>Thông tin</span>
            <span
              v-if="formData.updated_at"
            >Cập nhật lần cuối: {{ formData.updated_at }}</span>
          </el-row>
        </div>
        <div class="p-3">
          <div v-for="i in formInputs" :key="i.value">
            <input-type
              v-if="i.type === 'input'"
              :ref="i.ref"
              :info="i"
              :data="formData"
            />
            <textarea-type
              v-if="i.type === 'textarea'"
              :info="i"
              :data="formData"
            />
            <number-type
              v-if="i.type === 'number'"
              :info="i"
              :data="formData"
            />
            <editor-type
              v-if="i.type === 'editor'"
              :info="i"
              :data="formData"
            />
          </div>
        </div>
      </div>
      <div v-for="card in lefts" :key="card">
        <list-facility
          v-if="card === 'facility'"
          :data="formData"
          :type="type"
        />
        <services v-if="card === 'services'" :data="formData" :type="type" />
        <schedule v-if="card === 'schedule'" :data="formData" :type="type" />
        <artifacts v-if="card === 'artifacts'" :data="formData" :type="type" />
        <artifact-groups
          v-if="card === 'artifact_groups'"
          :data="formData"
          :type="type"
        />
        <groups v-if="card === 'groups'" :data="formData" :type="type" />
        <rooms v-if="card === 'rooms'" :data="formData" :type="type" />
        <banner v-if="card === 'banner'" :data="formData" :type="type" />
        <foods v-if="card === 'foods'" :data="formData" :type="type" />
        <food-groups
          v-if="card === 'food_groups'"
          :data="formData"
          :type="type"
        />
      </div>
    </el-col>
    <el-col v-if="!loading" :lg="9" class="shared-show__right">
      <div v-for="card in rights" :key="card">
        <display v-if="card === 'display'" :data="formData" :type="type" />
        <cam-display
          v-if="card === 'cam_display'"
          :data="formData"
          :type="type"
        />
        <cam-type v-if="card === 'cam_type'" :data="formData" :type="type" />
        <dates v-if="card === 'dates'" :data="formData" :type="type" />
        <price v-if="card === 'price'" :data="formData" :type="type" />
        <days v-if="card === 'days'" :data="formData" :type="type" />
        <hours v-if="card === 'hours'" :data="formData" :type="type" />
        <address-info v-if="card === 'address'" :data="formData" :type="type" />
        <contact v-if="card === 'contact'" :data="formData" :type="type" />
        <category v-if="card === 'category'" :data="formData" :type="type" />
        <youtube v-if="card === 'youtube'" :data="formData" :type="type" />
        <audios v-if="card === 'audios'" :data="formData" :type="type" />
        <pictures v-if="card === 'pictures'" :data="formData" :type="type" />
        <videos v-if="card === 'videos'" :data="formData" :type="type" />
        <country v-if="card === 'country'" :data="formData" :type="type" />
        <place v-if="card === 'place'" :data="formData" :type="type" />
        <place-parent
          v-if="card === 'place_parent' && formData.parent_id"
          :data="formData"
          :type="type"
        />
        <parent v-if="card === 'parent'" :data="formData" :type="type" />
        <packages v-if="card === 'packages'" :data="formData" :type="type" />
        <status v-if="card === 'status'" :data="formData" :type="type" />
      </div>
    </el-col>
    <el-button
      class="btn-save"
      type="success"
      circle
      title="Lưu thông tin"
      @click="updateDetailInfo"
    >
      <i class="fas fa-save"></i>
    </el-button>
  </el-row>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
// INFO
import InputType from '../inputs/InputType'
import NumberType from '../inputs/NumberType'
import TextareaType from '../inputs/TextareaType'
import EditorType from '../inputs/EditorType'

// LEFT CARDS
import Services from '../cards/Services'
import Groups from '../cards/Groups'
import Artifacts from '../cards/Artifacts'
import ArtifactGroups from '../cards/ArtifactGroups'
import Rooms from '../cards/Rooms'
import Schedule from '../cards/Schedule'
import ListFacility from '../cards/ListFacility'
import Foods from '../cards/Foods'
import FoodGroups from '../cards/FoodGroups'

// RIGHT CARDS
import Banner from '../cards/Banner'
import Display from '../cards/Display'
import CamDisplay from '../cards/CamDisplay'
import CamType from '../cards/CamType'
import Dates from '../cards/Dates'
import Price from '../cards/Price'
import Days from '../cards/Days'
import Hours from '../cards/Hours'
import AddressInfo from '../cards/Address'
import Contact from '../cards/Contact'
import Category from '../cards/Category'
import Youtube from '../cards/Youtube'
import Audios from '../cards/Audios'
import Pictures from '../cards/Pictures'
import Videos from '../cards/Videos'
import Packages from '../cards/Packages'
import Parent from '../cards/Parent'
import Place from '../cards/Place'
import PlaceParent from '../cards/PlaceParent'
import Country from '../cards/Country'
import Status from '../cards/Status'

import { getPlaceById, updatePlace } from '@/services/place'
import { getTourById, updateTour } from '@/services/tour'
import { getPostById, updatePost } from '@/services/post'
import { getCampaignById, updateCampaign } from '@/services/campaign'

import CONSTANTS from '@/config/constants'

export default {
  name: 'SharedShow',
  components: {
    InputType,
    NumberType,
    TextareaType,
    EditorType,

    Services,
    Groups,
    Artifacts,
    ArtifactGroups,
    Rooms,
    ListFacility,
    Foods,
    FoodGroups,

    Banner,
    Display,
    CamDisplay,
    CamType,
    Dates,
    Price,
    Days,
    Hours,
    AddressInfo,
    Contact,
    Category,
    Youtube,
    Audios,
    Pictures,
    Videos,
    Packages,
    Parent,
    Place,
    PlaceParent,
    Country,
    Schedule,
    Status
  },
  props: {
    type: String,
    inputs: Array,
    lefts: Array,
    rights: Array
  },
  data() {
    return {
      loading: true,
      formData: null,
      callingAPI: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    formInputs() {
      return this.inputs.map((i) =>
        CONSTANTS.ALL_INFO_INPUTS.find((input) => input.value === i)
      )
    },
    requiredInputs() {
      return this.formInputs.filter((i) => i.required)
    },
    params() {
      return {
        id: this.$route.params.id,
        locale: this.language
      }
    }
  },
  watch: {
    language: 'getDetailInfo'
  },
  beforeMount() {
    this.getDetailInfo()
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),

    getDetailInfo() {
      let request = null
      if (this.type === 'tour') {
        request = getTourById(this.params)
      } else if (this.type === 'campaign') {
        request = getCampaignById(this.params)
      } else if (this.type === 'post') {
        request = getPostById(this.params)
      } else {
        request = getPlaceById(this.params)
      }
      this.loading = true
      request.then((response) => {
        this.formData = {
          ...response,
          location: response.location || null
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleValidate() {
      const invalids = []
      this.requiredInputs.forEach(i => {
        const v = this.$refs[i.ref][0].handleValidate()
        if (!v) {
          invalids.push(i)
        }
      })
      if (invalids.length > 0) {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Bạn chưa nhập thông tin bắt buộc..'
        })
        return false
      }
      return true
    },

    updateDetailInfo() {
      if (this.handleValidate()) {
        let request = null
        const data = { ...this.formData, locale: this.language }
        if (this.type === 'tour') {
          request = updateTour(data)
        } else if (this.type === 'campaign') {
          request = updateCampaign(data)
        } else if (this.type === 'post') {
          request = updatePost(data)
        } else {
          request = updatePlace(data)
        }
        this.callingAPI = true
        request.then(() => {
          this.$notify.success({
            title: 'Thông báo',
            message: 'Cập nhật thông tin thành công.'
          })
          this.callingAPI = false
          if (['country', 'area'].includes(this.type)) {
            this.updateReloadConstant(dayjs().unix())
          }
        }).catch((error) => {
          this.callingAPI = false
          this.$notify.error({
            title: 'Thông báo',
            message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shared-show {
  min-height: 800px;
  margin-bottom: 24px;
  &__left {
    padding-right: 6px;
  }
  &__right {
    padding-left: 6px;
  }
}
.btn-save {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  font-size: 24px;
}
</style>
<style lang="scss">
.shared-show {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
  }
}
</style>
