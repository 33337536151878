<template>
  <div class="banner one-component-detail-admin">
    <div class="title-component-admin">Banner campaign</div>
    <div class="banner__content p-3">
      <img v-if="data.avatar" :src="data.avatar.url" alt="href" />
      <p v-else class="m-0">
        Chưa có Banner
      </p>
      <el-upload
        class="avatar-uploader"
        accept="image/*"
        list-type="picture"
        multiple
        action
        :show-file-list="false"
        :http-request="uploadFiles"
      >
        <el-button size="small" type="primary" class="mt-2" :loading="callingAPI">
          {{ data.avatar ? 'Đổi banner' : 'Tải ảnh lên' }}
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createStorage } from '@/services/storage'
export default {
  name: 'Banner',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false
    }
  },
  computed: {
    ...mapGetters(['constant', 'language'])
  },
  methods: {
    uploadFiles(fileInfo) {
      const params = {
        file: fileInfo.file,
        objectId: this.data.id,
        objectType: this.type,
        fileType: 'image',
        locale: this.language
      }
      this.callingAPI = true
      createStorage(params).then((res) => {
        this.data.avatar = res.data
        this.data.avatar_id = res.data.id
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể upload file: ' + fileInfo.file.name
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  &__content {
    text-align: center;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border: 1px solid #f5f5f5;
    }
  }
}
</style>
