var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hours one-component-detail-admin"},[_c('div',{staticClass:"title-component-admin"},[_vm._v("Giờ hoạt động")]),_c('el-form',{staticClass:"p-3",attrs:{"model":_vm.data.opening_time}},[_c('el-row',{attrs:{"type":"flex","justify":"space-between","gutter":12}},[_c('el-col',{attrs:{"lg":12}},[_c('el-form-item',{attrs:{"label":"Giờ mở cửa"}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:00',
              step: '00:15',
              end: '23:45',
              maxTime: _vm.data.opening_time.close_time,
            },"size":"medium","placeholder":"Chọn giờ"},model:{value:(_vm.data.opening_time.open_time),callback:function ($$v) {_vm.$set(_vm.data.opening_time, "open_time", $$v)},expression:"data.opening_time.open_time"}})],1)],1),_c('el-col',{attrs:{"lg":12}},[_c('el-form-item',{attrs:{"label":"Giờ đóng cửa"}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:00',
              step: '00:15',
              end: '23:45',
              minTime: _vm.data.opening_time.open_time,
            },"size":"medium","placeholder":"Chọn giờ"},model:{value:(_vm.data.opening_time.close_time),callback:function ($$v) {_vm.$set(_vm.data.opening_time, "close_time", $$v)},expression:"data.opening_time.close_time"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }